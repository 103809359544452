import { useSelector, useDispatch } from "react-redux";
import Layout from "../../sistema/components/Layout";
import { RootState } from "../../app/mainReducer";
import { bodyCenterActions } from "../reducer";
import { useEffect } from "react";
import { SelectClient } from "../components/SelectClient";
import Loader from "../../sistema/components/Loader";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, useDisclosure } from "@chakra-ui/react";
import { FiDownload, FiRotateCw } from "react-icons/fi";
import { UnitCard } from "../components/UnitCard";
import { DownloadCertificatesModal } from "../components/DownloadCertificatesModal";

export const BodyCenterDashboard = () => {

  const dashboard = useSelector((state: RootState) => state.bodyCenter?.dashboard);
  const isLoading = useSelector((state: RootState) => !!state.bodyCenter?.isLoading);
  const dispatch = useDispatch();

  const { client } = useParams();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    dispatch(bodyCenterActions.bodyCenterDashboardRequest({
      client: client as string,
      units: (searchParams.get('units') ?? '')?.split(','),
    }));
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if ((dashboard?.clients ?? []).length > 0) {
      if (dashboard?.selectedClient) {
        const baseUrl = `/body-center-dashboard${dashboard.selectedClient ? `/${dashboard.selectedClient.id}` : ''}`;
        const queryParams = dashboard.selectedUnits?.length
          ? `?units=${dashboard.selectedUnits.join(',')}`
          : '';

        navigate(`${baseUrl}${queryParams}`, { replace: true });
      } else {
        navigate('/body-center-dashboard', { replace: true });
      }
    }
  }, [dashboard?.clients, dashboard?.selectedClient, dashboard?.selectedUnits]);

  const { t } = useTranslation();

  const isMobile = useSelector((state: RootState) => state.sistema?.isMobile);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const lastAllBodyCenterVisits = (dashboard?.selectedClient?.unidades ?? []).filter(unit => (dashboard?.selectedUnits ?? []).map((u) => Number(u))?.includes(Number(unit.id))).map((unit) => unit.bodyCenterVisits ?? [])



  console.log(lastAllBodyCenterVisits);

  return (
    <Layout padding={0}>
      <div className="w-full min-h-screen bg-gray-50">
        <Loader isLoading={isLoading} />

        <div className="w-full bg-white shadow-sm py-4 px-4">
          <div className="max-w-7xl mx-auto">
            <div className="flex flex-col md:flex-row justify-between items-center gap-4">
              <h1 className="text-3xl font-bold text-gray-800 bg-gradient-to-r from-green-900 to-green-600 bg-clip-text text-transparent">
                {t('MenuDashboardBodyCenter')}
              </h1>

              <div className="w-full md:w-96">
                <SelectClient />
              </div>
            </div>

            {dashboard?.selectedClient && (
              <div className="mt-4 text-gray-600">
                <span className="font-normal">{t('BodyCenterSelectedClient')}: </span>
                <span className="text-green-800">{dashboard.selectedClient.nome}</span>
                {isMobile && <div className="w-full"></div>}
                {(dashboard?.clients ?? []).length > 1 && <span className="text-green-800">
                  <Button
                    className="ml-0 md:ml-4 mt-2 md:mt-0"
                    size="sm"
                    variant="outline"
                    colorScheme="green"
                    leftIcon={<FiRotateCw />}
                    onClick={() => dispatch(bodyCenterActions.bodyCenterDashboardSelectClient(undefined))}
                  >
                    {t('BodyCenterChangeClient')}
                  </Button>
                </span>}
                <span className="text-green-800">
                  <Button
                    className={(dashboard?.clients ?? []).length > 1 ? 'ml-4 mt-2 md:mt-0' : 'md:ml-4 mt-2 md:mt-0'}
                    size="sm"
                    variant="outline"
                    colorScheme="green"
                    leftIcon={<FiRotateCw />}
                    onClick={() => dispatch(bodyCenterActions.bodyCenterDashboardSelectClientUnits([]))}
                  >
                    {t('BodyCenterChangeClientUnit')}
                  </Button>
                </span>
                <span className="text-green-800">
                  <Button
                    className="md:ml-4 mt-2 md:mt-0"
                    size="sm"
                    variant="outline"
                    colorScheme="green"
                    leftIcon={<FiDownload />}
                    onClick={onOpen}
                  >
                    {t('DownloadCertificates')}
                  </Button>
                </span>
              </div>
            )}
          </div>
        </div>

        <DownloadCertificatesModal
          isOpen={isOpen}
          onClose={onClose}
          visits={lastAllBodyCenterVisits.flat().sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())}
        />

        <div className="max-w-full mx-auto mt-2 px-2 md:px-2">
          <div className="max-w-full mx-auto">
            <div className="grid grid-cols-1 md:grid-cols-3 gap-1">
              {(dashboard?.selectedClient?.unidades ?? []).filter(unit => (dashboard?.selectedUnits ?? []).map((u) => Number(u))?.includes(Number(unit.id))).map((unit) => (
                <UnitCard unit={unit} key={unit.id} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};
