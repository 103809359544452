// src/modules/body-center/components/DownloadCertificatesModal.tsx
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Checkbox,
  VStack,
  HStack,
  Text,
  useToast,
  Badge,
  Box,
  OrderedList,
  ListItem,
  Divider,
  Tooltip,
  IconButton,
  Collapse,
  ModalFooter,
} from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FiDownload, FiHelpCircle, FiInfo } from "react-icons/fi";
import { BodyCenterVisit } from "../types/body_center_visit";
import { formatData, getCurrentData } from "../../utils/data";
import axios from "axios";
import { apiCall, APIURL } from "../../utils/apiCall";

interface DownloadCertificatesModalProps {
  isOpen: boolean;
  onClose: () => void;
  visits: BodyCenterVisit[];
}

export const DownloadCertificatesModal = ({
  isOpen,
  onClose,
  visits,
}: DownloadCertificatesModalProps) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [selectedVisits, setSelectedVisits] = useState<number[]>([]);

  const handleToggleVisit = (visitId: number) => {
    setSelectedVisits((prev) =>
      prev.includes(visitId)
        ? prev.filter((id) => id !== visitId)
        : [...prev, visitId]
    );
  };

  const handleDownloadSelected = async () => {
    if (selectedVisits.length === 0) {
      toast({
        title: t("SelectAtLeastOneCertificate"),
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const link = document.createElement('a');
    link.href = `${APIURL}/body-center-visit-certificates`;
    link.download = 'certificates.zip';
    const params = new URLSearchParams();
    selectedVisits.forEach(visitId => {
      params.append('body_center_visits[]', visitId.toString());
    });
    link.href += `?${params.toString()}`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    onClose();
  };

  const [showHelp, setShowHelp] = useState(false);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className="bg-green-50 text-green-800">
          {t("DownloadCertificates")}
          <Tooltip label={t("BodyCenterNeedHelp")}>
            <IconButton
              aria-label="Help"
              icon={<FiHelpCircle />}
              variant="ghost"
              colorScheme="green"
              size="sm"
              onClick={() => setShowHelp(!showHelp)}
            />
          </Tooltip>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody className="py-6">
          <Collapse in={showHelp} animateOpacity>
            <Box bg="green.50" p={4} borderRadius="md" mb={6}>
              <HStack spacing={2} mb={3}>
                <FiInfo className="text-green-600" />
                <Text fontWeight="medium" color="green.700">
                  {t("HowToDownloadCertificates")}
                </Text>
              </HStack>
              <OrderedList spacing={2} pl={4} color="green.700">
                <ListItem>{t("BodyCenterDownloadStep1")}</ListItem>
                <ListItem>{t("BodyCenterDownloadStep2")}</ListItem>
                <ListItem>{t("BodyCenterDownloadStep3")}</ListItem>
                <ListItem>{t("BodyCenterDownloadStep4")}</ListItem>
              </OrderedList>
            </Box>
          </Collapse>

          <VStack spacing={4} align="stretch" style={{
            maxHeight: "calc(100vh - 300px)",
            overflowY: 'auto'
          }}>
            {visits.map((visit) => (
              <HStack
                key={visit.id}
                p={3}
                bg="white"
                borderRadius="md"
                border="1px"
                borderColor="gray.200"
                justify="space-between"
                onClick={() => visit.certificate && handleToggleVisit(visit.id as number)}
                cursor={visit.certificate ? "pointer" : "not-allowed"}
                _hover={{
                  bg: visit.certificate ? "gray.50" : "white"
                }}
              >
                <HStack>
                  <Checkbox
                    size="lg"
                    isChecked={selectedVisits.includes(visit.id as number)}
                    onChange={() => handleToggleVisit(visit.id as number)}
                    isDisabled={!visit.certificate}
                  />
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="medium">
                      {formatData(visit.date, 'dd/MM/yyyy')}
                      {visit?.service_number ? ` - OSC: ${visit.service_number}` : ""}
                      - {visit.clientUnit?.nome_referencia}
                    </Text>
                    {!visit.certificate && (
                      <Badge colorScheme="red">
                        {t("CertificateNotAvailable")}
                      </Badge>
                    )}
                  </VStack>
                </HStack>
              </HStack>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack justify="flex-end">
            <Button variant="ghost" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <Button
              colorScheme="green"
              leftIcon={<FiDownload />}
              onClick={handleDownloadSelected}
              isDisabled={selectedVisits.length === 0}
            >
              {t("DownloadSelected")}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};