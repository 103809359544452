import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../app/mainReducer";
import { useIsAuth } from "../../hooks/useIsAuth"
import { useParams } from "react-router-dom";
import React, { useEffect } from 'react'
import { Formik, Form } from 'formik'
import { Badge, Button, Flex } from "@chakra-ui/react";
import { bodyCenterActions } from "../reducer";
import { ListHeader } from "../../sistema/components/ListHeader";
import Layout from "../../sistema/components/Layout";
import { DefaultButton } from "../../sistema/components/DefaultButton";
import Loader from "../../sistema/components/Loader";
import validateForm from "../../utils/validateForm";
import { useTranslation } from "react-i18next";
import { sistemaActions } from "../../sistema/recucer";
import InputField from "../../sistema/components/InputField";
import InputSelect from "../../sistema/components/InputSelect";
import Accordion from "../../sistema/components/Accordion";
import { BackButton } from "../../sistema/components/BackButton";
import { Filhos } from "../../sistema/components/Filhos";
import { newBodyCenterVisitNonconformity } from "../data/body_center_visit_nonconformity";
import { TableHeaders } from "../../sistema/components/TableFixed";
import { BodyCenterVisitNonconformity } from "../types/body_center_visit_nonconformity";
import { newBodyCenterVisitNonconformityFile } from "../data/body_center_visit_nonconformity_file";
import { BodyCenterVisitNonconformityFile } from "../types/body_center_visit_nonconformity_file";
import InputFile from "../../sistema/components/InputFile";
import { newBodyCenterVisitSighting } from "../data/body_center_visit_sighting";
import { BodyCenterVisitSighting } from "../types/body_center_visit_sighting";
import InputNumber from "../../sistema/components/InputNumber";
import { newBodyCenterVisitTrace } from "../data/body_center_visit_trace";
import { BodyCenterVisitTrace } from "../types/body_center_visit_trace";
import { BodyCenterVisit } from "../types/body_center_visit";
import { openLink } from "../../utils/openLink";
import { FiEdit, FiSave } from "react-icons/fi";
import { BodyCenterOccurrences } from "../components/BodyCenterOccurrences";
import { getConfigurationOfPointsInMap } from "../utils/bodyCenterMap";

export const BodyCenterVisitEdit = () => {
  useIsAuth();

  const { t } = useTranslation()
  const { id } = useParams();

  const visit = useSelector((state: RootState) => state.bodyCenter.visit)
  const isLoading = useSelector((state: RootState) => !!state.bodyCenter.isLoading)

  const todosClientes = useSelector((state: RootState) => state.sistema.todosClientes || []);

  const dispatch = useDispatch()

  useEffect(() => {
    if (!id) {
      return;
    }

    dispatch(sistemaActions.todosClientesRequest())
    dispatch(bodyCenterActions.visitRequest(id))
  }, [dispatch, id])

  const getSectors = (values: BodyCenterVisit) => {
    const sectors = new Set([
      ...(values?.sightings?.map((i) => i.sector) ?? []),
      ...(values?.traces?.map((i) => i.sector) ?? []),
    ]);
    return Array.from(sectors);
  }

  return (
    <Layout>
      <ListHeader label={t('BodyCenterSingular')}>
        <BackButton ml={4} to="/body-center-visits"> {t('SistemaVoltar')} </BackButton>
      </ListHeader>

      <Loader isLoading={isLoading} />

      {visit && <Formik
        initialValues={visit}
        enableReinitialize
        onSubmit={(values, { setErrors }) => {
          const validation = validateForm({
            date: 'required',
            client_id: 'required',
            client_unit_id: 'required',
          }, values, t)
          if (Object.keys(validation).length > 0) {
            setErrors(validation)
            return;
          }

          console.log("visit form", values);
          dispatch(bodyCenterActions.saveVisitRequest(values));
        }}
      >
        {({ values, setFieldValue, submitForm }) => (
          <Form>
            <Flex width="full" wrap={"wrap"}>

              <div className="w-full grid grid-cols-1 md:grid-cols-4 gap-1">
                <InputField name="date" label={t('SistemaData')} type="date" />
                <InputSelect label={t('UsuarioCliente')} name="client_id">
                  {todosClientes && todosClientes.map((i) => (
                    <option value={i.id} key={i.id}>{i.nome}</option>
                  ))}
                </InputSelect>

                <InputSelect onChangeVal={(e: any) => {
                  const clientUnit = (todosClientes.find((i: any) => i.id == values.client_id)?.unidades ?? []).find((i) => i.id == e);
                  setFieldValue('clientUnit', clientUnit);
                  setFieldValue('occurrences', getConfigurationOfPointsInMap(values, clientUnit));
                }} label={t('DashboardUnidade')} name="client_unit_id">
                  {todosClientes && (todosClientes.find((i: any) => i.id == values.client_id)?.unidades ?? []).map((i) => (
                    <option value={i.id} key={i.id}>{i.nome_referencia}</option>
                  ))}
                </InputSelect>

                <InputField name="service_number" label={t('BodyCenterServiceNumber')} />
                <InputFile name="certificate" label={t('BodyCenterCertificate')} />
              </div>

              <InputField className="w-full" name="observation" label={t('BodyCenterObservation')} textarea rows={4} />

              <Accordion className="w-full mt-4" items={[
                {
                  title: `${t('BodyCenterOccurrences')} (${values?.occurrences?.length ?? 0} ${t((values?.occurrences?.length ?? 0) != 1 ? 'SistemaRegistros' : 'SistemaRegistro').toLowerCase()})`,
                  content: <BodyCenterOccurrences setFieldValue={setFieldValue} key={`${values.clientUnit?.id}-${values.clientUnit?.updated_at}`} />
                },
                {
                  title: `${t('BodyCenterNonconformities')} (${values?.nonconformities?.length ?? 0} ${t((values?.nonconformities?.length ?? 0) != 1 ? 'SistemaRegistros' : 'SistemaRegistro').toLowerCase()})`,
                  content: (
                    <div>
                      <Filhos
                        minH={''}
                        name="nonconformities"
                        tituloForm={t('BodyCenterNonconformities')}
                        editTituloForm={t('BodyCenterNonconformity')}
                        novoItemObject={newBodyCenterVisitNonconformity()}
                        afterSave={submitForm}
                        headers={[
                          {
                            label: t('BodyCenterNonconformityQuantity'),
                            wrapped: false,
                            render: (item) => item?.quantity
                          },
                          {
                            label: t('SistemaArquivos'),
                            wrapped: false,
                            render: (item) => <React.Fragment>
                              <Badge colorScheme="blue">
                                {item?.files?.length} {t('SistemaArquivos')}
                              </Badge>
                            </React.Fragment>
                          }
                        ] as TableHeaders<BodyCenterVisitNonconformity>[]}
                        form={(values) => (
                          <Flex width={"full"} wrap={"wrap"}>
                            <InputNumber decimalPlaces={0} name="quantity" label={t('BodyCenterNonconformityQuantity')} />

                            <Filhos
                              minH={''}
                              name="files"
                              tituloForm={t('SistemaArquivos')}
                              editTituloForm={t('SistemaArquivo')}
                              novoItemObject={newBodyCenterVisitNonconformityFile()}
                              validation={{ file_url: "required", file_name: "required" }}
                              headers={[
                                {
                                  label: t('SistemaArquivo'),
                                  wrapped: true,
                                  notClicable: true,
                                  render: (item) => <>
                                    <Button colorScheme="blue" onClick={() => openLink(item.file_url)}>
                                      Download {item?.file_name}
                                    </Button>
                                  </>
                                },
                                {
                                  label: '',
                                  wrapped: true,
                                  render: (item) => <>
                                    <Button colorScheme="blackAlpha" className="flex items-center gap-2">
                                      {t('SistemaEditar')} <FiEdit />
                                    </Button>
                                  </>
                                },
                              ] as TableHeaders<BodyCenterVisitNonconformityFile>[]}
                              form={(values) => (
                                <Flex width={"full"} wrap={"wrap"}>
                                  <InputField className="w-full" name="file_name" label={t('SistemaArquivoNome')} type="text" />
                                  <InputFile name="file_url" label={t('SistemaArquivoUrl')} />
                                </Flex>
                              )}
                            />
                          </Flex>
                        )}
                      />
                    </div>
                  )
                },
                {
                  title: `${t('BodyCenterSightings')} (${values?.sightings?.length ?? 0} ${t((values?.sightings?.length ?? 0) != 1 ? 'SistemaRegistros' : 'SistemaRegistro').toLowerCase()})`,
                  content: (
                    <div>
                      <Filhos
                        minH={''}
                        name="sightings"
                        tituloForm={t('BodyCenterSightings')}
                        editTituloForm={t('BodyCenterSighting')}
                        novoItemObject={newBodyCenterVisitSighting()}
                        validation={{ sector: "required" }}
                        afterSave={submitForm}
                        headers={[
                          {
                            label: t('BodyCenterSector'),
                            wrapped: false,
                            render: (item) => item.sector,
                          },
                          {
                            label: '',
                            wrapped: false,
                            render: (item) => <Badge colorScheme="blue">
                              {item.quantity_alive} {t('BodyCenterAlive')}
                            </Badge>
                          },
                          {
                            label: '',
                            wrapped: false,
                            render: (item) => <Badge colorScheme="red">
                              {item.quantity_death} {t('BodyCenterDeath')}
                            </Badge>
                          }
                        ] as TableHeaders<BodyCenterVisitSighting>[]}
                        form={(_) => (
                          <Flex width={"full"} wrap={"wrap"}>
                            <InputField className="w-full" name="sector" label={t('BodyCenterSector')} />
                            {getSectors(values).length > 0 && <div className="w-full ml-1">
                              {t('BodyCenterSectorsPutedInLast')}: {getSectors(values).join(',')}
                            </div>}
                            <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-4">
                              <InputNumber decimalPlaces={0} name="quantity_alive" label={t('BodyCenterAlive')} />
                              <InputNumber decimalPlaces={0} name="quantity_death" label={t('BodyCenterDeath')} />
                            </div>
                          </Flex>
                        )}
                      />
                    </div>
                  )
                },
                {
                  title: `${t('BodyCenterTraces')} (${values?.traces?.length ?? 0} ${t((values?.traces?.length ?? 0) != 1 ? 'SistemaRegistros' : 'SistemaRegistro').toLowerCase()})`,
                  content: (
                    <div>
                      <Filhos
                        minH={''}
                        name="traces"
                        tituloForm={t('BodyCenterTraces')}
                        editTituloForm={t('BodyCenterTrace')}
                        novoItemObject={newBodyCenterVisitTrace()}
                        validation={{ sector: "required" }}
                        afterSave={submitForm}
                        headers={[
                          {
                            label: t('BodyCenterSector'),
                            wrapped: false,
                            render: (item) => item.sector,
                          },
                          {
                            label: '',
                            wrapped: false,
                            render: (item) => <Badge colorScheme="blue">
                              {item.quantity} {t('BodyCenterTraces')}
                            </Badge>
                          },
                        ] as TableHeaders<BodyCenterVisitTrace>[]}
                        form={(_) => (
                          <Flex width={"full"} wrap={"wrap"}>
                            <InputField className="w-full" name="sector" label={t('BodyCenterSector')} />
                            {getSectors(values).length > 0 && <div className="w-full ml-1">
                              {t('BodyCenterSectorsPutedInLast')}: {getSectors(values).join(',')}
                            </div>}
                            <InputNumber decimalPlaces={0} name="quantity" label={t('BodyCenterTraces')} />
                          </Flex>
                        )}
                      />
                    </div>
                  )
                }
              ]} />

              <hr style={{ width: "100%", marginTop: 12, marginBottom: 6 }} />

              <DefaultButton type="submit" mt={2} className="flex items-center gap-2">
                <FiSave /> {t('SistemaSalvar')} {t('BodyCenterSingular')}
              </DefaultButton>
            </Flex>
          </Form>
        )}
      </Formik>}
    </Layout>
  )
}
