import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";

export const newBodyCenterVisitOccurrence = (): BodyCenterVisitOccurrence => {
  return {
    body_center_visits_id: "",
    point_id: "",
    point: 0,
    bait_type: 0,
    bait_type_occurrence: 1,
  };
};
