import { useFormikContext } from "formik";
import { BodyCenterVisit } from "../types/body_center_visit";
import { Alert, Badge, Box } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import MapBait from "./Mapbait";
import InputSelect from "../../sistema/components/InputSelect";
import { getBaitsTypes } from "../utils/bodyCenterBaits";
import { BodyCenterVisitOccurrence } from "../types/body_center_visit_occurrence";
import config from "../../../config";
import React from "react";

interface BodyCenterOccurrencesProps {
  setFieldValue: (field: string, value: any) => void;
}

export const BodyCenterOccurrences = ({ setFieldValue }: BodyCenterOccurrencesProps) => {
  const { values } = useFormikContext<BodyCenterVisit>();

  const { t } = useTranslation();

  if (!values.client_unit_id) {
    return (
      <Alert className="w-full" status="warning">
        {t('BodyCenterSelectUnit')}
      </Alert>
    )
  }

  if (!values.client_unit_id) {
    return (
      <Alert className="w-full" status="warning">
        {t('BodyCenterSelectUnit')}
      </Alert>
    )
  }

  if (!values.clientUnit?.body_center_map_image) {
    return (
      <Alert className="w-full" status="warning">
        {t('BodyCenterSelectUnitNoImage')}
      </Alert>
    )
  }

  const isConsumed = (occurrence: BodyCenterVisitOccurrence) => {
    const ocurrences = (getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []);
    if (!ocurrences.length) {
      return false;
    }
    return ocurrences.find(o => o.value == occurrence.bait_type_occurrence)?.isConsumed == 1;
  }

  return (
    <div className="w-full">
      <Box
        overflowX="auto"
        overflowY="hidden"
        className="mx-auto max-w-[85vw] md:max-w-auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '12px',
            height: '12px',
            backgroundColor: '#F5F5F5',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: config.defaultColor,
            borderRadius: '2px',
            '&:hover': {
              backgroundColor: config.defaultColor,
            },
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#F5F5F5',
            borderRadius: '2px',
          },
        }}
      >
        <Box minWidth="1100px" minHeight="500px" position="relative">
          <MapBait
            baseImage={values.clientUnit.body_center_map_image}
            clientUnit={values.clientUnit}
            setPosition={(position: any) => { }}
            setPoints={(points: any) => { }}
            isStatic={true}
          />
          <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            onTouchStart={(e) => e.stopPropagation()}
            onClick={(e) => e.stopPropagation()}
            pointerEvents="auto"
            sx={{
              touchAction: "pan-x",
              userSelect: "none",
              overscrollBehavior: "contain"
            }}
          />
        </Box>
      </Box>

      <div className="max-w-[900px] mx-auto">
        {(values.occurrences ?? []).map((occurrence, key) => (
          <React.Fragment key={`${occurrence.id}-${key}-${occurrence.point}${occurrence.point_id}`}>
            {occurrence.deleted ? <React.Fragment></React.Fragment> :
              <div className="my-3 w-full flex flex-wrap border border-gray-200 rounded-md px-2 shadow-md">
                <div className="w-full md:w-1/5 pt-1 pb-2">
                  <Badge colorScheme="blue" className="w-full text-center">
                    <span className="my-auto">{t('DashboardPonto')} {occurrence.point} <small>(id: {occurrence.point_id})</small> </span>
                  </Badge>
                </div>
                <div className={`w-full ${isConsumed(occurrence) ? 'md:w-3/5' : 'md:w-4/5'}`}>
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <InputSelect onChangeVal={(val) => val ? setFieldValue(`occurrences[${key}].bait_type_occurrence`, 1) : setFieldValue(`occurrences[${key}].bait_type_occurrence`, 0)} inputSize="xs" name={`occurrences[${key}].bait_type`}>
                      <option value="0">{t('BodyCenterSelectBaitType')}</option>
                      {getBaitsTypes().map((baitType, index) => (
                        <option key={`${baitType.value}-${index}`} value={baitType.value}>{t(baitType.name)}</option>
                      ))}
                    </InputSelect>
                    <InputSelect inputSize="xs" name={`occurrences[${key}].bait_type_occurrence`}>
                      {occurrence.bait_type == 0 && <option value="">{t('BodyCenterSelectBaitTypeOccurrence')}</option>}
                      {(getBaitsTypes().find(baitType => baitType.value == occurrence.bait_type)?.occurrences ?? []).map((baitTypeOccurrence, index) => (
                        <option key={`${baitTypeOccurrence.value}-${index}`} value={baitTypeOccurrence.value}>{t(baitTypeOccurrence.name)} {baitTypeOccurrence.isConsumed == 1 && <> ({t('BodyCenterConsumedRegister')})</>}</option>
                      ))}
                    </InputSelect>
                  </div>
                </div>

                {isConsumed(occurrence) && (
                  <div className="w-full md:w-1/5 pt-1 pb-2">
                    <Badge colorScheme="red" className="w-full text-center">
                      <span className="my-auto">{t('BodyCenterConsumed')}</span>
                    </Badge>
                  </div>
                )}
              </div>
            }
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}