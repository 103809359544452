import { ClienteUnidade } from "../../sistema/types/cliente_unidade";
import { newBodyCenterVisitOccurrence } from "../data/body_center_visit_occurrence";
import { BodyCenterVisit } from "../types/body_center_visit";

export const getConfigurationOfPointsInMap = (bodyCenter: BodyCenterVisit, clientUnit?: ClienteUnidade) => {
  const client = clientUnit ? clientUnit : bodyCenter.clientUnit;
  const configuredPoints = (client?.body_center_map_points ?? []).filter((i) => !i.src).map((i) => ({ point: i.text, id: i.id }));
  const currentOccurrences = (bodyCenter.occurrences ?? []).map((ocur) => {
    const existsInMap = configuredPoints.some((point) => point.id == ocur.point_id);

    if (! existsInMap) {
      ocur.deleted = true;
      ocur.changed = true;
    }

    return ocur;
  });

  const newPoints = configuredPoints.filter((a) => !currentOccurrences.some((curr) => curr.point_id == a.id)).map(item => ({
    ...newBodyCenterVisitOccurrence(),
    point: item.point,
    point_id: item.id,
    deleted: false,
    changed: true,
  }));


  return [...newPoints, ...currentOccurrences].sort((a, b) => `${String(a.point).padStart(10, '0')}${a.id}`.localeCompare(`${String(b.point).padStart(10, '0')}${b.id}`));
}
